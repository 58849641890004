<template>
  <div>
    <div v-if="$store.state.show">
      <h2>IOS端</h2>
      <h3>1.API更新</h3>
      <ul>
        <li>（1）移除API：</li>
        <img src="@/assets/developer/ios4.png" alt="" />
        <img src="@/assets/developer/ios5.png" alt="" />
        <img src="@/assets/developer/ios6.png" alt="" />
        <li>（2）新增API：</li>
        <img src="@/assets/developer/ios7.png" alt="" />
        <li>（3）旧版的三种在线初始化方法合并为一种：</li>
        <img src="@/assets/developer/ios8.png" alt="" />
        <li>（4）只保留新版设置滤镜的方法</li>
        <img src="@/assets/developer/ios9.png" alt="" />
        <li>
          （5）不再使用以下文件，替换为从 <span>TiSDKResource.bundle</span> 对应
          文件夹中获取的 <span>json</span> ，方便以后热更新数据
        </li>
        <img src="@/assets/developer/ios10.png" alt="" />
      </ul>
      <h3>2.如何更新</h3>
      <ul>
        <li>
          （1）如果之前版本使用的是默认UI且未做任何修改的用户<br />
          第一步、先从xcode里彻底移除老版 <span>TiSDK_iOS</span> ，重新导入新版
          <span>TiSDK_iOS</span>（不需要重新集成，但重新导入的时候需要做动
          态库嵌入）<br />
          第二步、修改初始化方法即可
          <pre v-highlightA>
          <code>
            [[TiSDKshareInstance]initSDK:keywithDelegate:self];
          </code>
          </pre>
        </li>
        <li>
          （2）如果之前版本使用的是默认UI且做过自定义修改的或者不
          使用默认UI自己重写的用户<br />
          第一二步、同上<br />
          第三步、参考以上API的改动、修改对应代码
          （注意新版获取对应路径的时候需要拼上“/”）
        </li>
        <img src="@/assets/developer/ios11.png" alt="" />
      </ul>
    </div>
    <div v-else>
      <h2>IOS</h2>
      <h3>1.API update</h3>
      <ul>
        <li>（1）Remove API：</li>
        <img src="@/assets/developer/ios4.png" alt="" />
        <img src="@/assets/developer/ios5.png" alt="" />
        <img src="@/assets/developer/ios6.png" alt="" />
        <li>（2）New API：</li>
        <img src="@/assets/developer/ios7.png" alt="" />
        <li>
          （3）The three online initialization methods of the old version are
          combined into one：
        </li>
        <img src="@/assets/developer/ios8.png" alt="" />
        <li>（4）Just keep the new filter setting method</li>
        <img src="@/assets/developer/ios9.png" alt="" />
        <li>
          （5）Instead of using the following files, replace them with
          <span>json</span> files obtained from the corresponding folder of
          <span>TiSDKResource</span>. Bundle to facilitate hot update data later
        </li>
        <img src="@/assets/developer/ios10.png" alt="" />
      </ul>
      <h3>2.如何更新</h3>
      <ul>
        <li>
          （1）If the previous version used the default UI and did not make any
          changes<br />
          The first step、First completely remove the old
          <span>TiSDK iOS</span> from Xcode, and re-import the new
          <span>TiSDK iOS</span> (no need to reintegrate, but you need to embed
          the dynamic library when reimporting)<br />
          The second step、Modify the initialization method
          <pre v-highlightA>
          <code>
            [[TiSDKshareInstance]initSDK:keywithDelegate:self];
          </code>
          </pre>
        </li>
        <li>
          （2）If the previous version used the default UI and made custom
          changes or did not use the default UI to rewrite the user<br />
          Step 1 and 2、ditto<br />
          The third step 、Refer to the API changes above to modify the
          corresponding code （Note that the new version requires spelling when
          obtaining the corresponding path“/”）
        </li>
        <img src="@/assets/developer/ios11.png" alt="" />
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
span {
  color: #e91e63;
}
img {
  width: 470px;
  margin: 20px 50px;
}
li {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
.ul_style {
  padding-left: 10px;
  li {
    line-height: 32px;
    list-style: disc inside none;
  }
  a {
    color: #4c8cf5;
    text-decoration: underline;
  }
}
</style>